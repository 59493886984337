import React, { useEffect, useState, useRef } from "react";
// import emailjs from "@emailjs/browser";
import { Link, Redirect, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { FullColumn } from "../../../components/utility/rowColumn";
import axios from "axios";
import { logigloUrl } from "../../Config/index";
import CustomIcons from "../../../assests/messages";
// import { MdOutlineForum } from "react-icons/md";
import { FaForumbee } from "react-icons/fa";
import useAOS from "../../scrollAnimation";
import Aos from 'aos';
import 'aos/dist/aos.css'

import "./index.css";
import { duration } from "moment";

const RecentPostsSection = () => {
  const [posts, setPosts] = useState([]);
  const jwtToken = localStorage.getItem("token");
  const history = useHistory();


  console.log(jwtToken, "jt_token")

  console.log(posts, "posts")

  let account_id, accountType;

  if (jwtToken) {
    const tokeData = JSON.parse(jwtToken);
    account_id = tokeData.accountId;
    accountType = tokeData.accountType;
  }


  useAOS();
  // Posts with pagination
  const fetchData = async (page) => {
    const pageNumber = isNaN(page) ? 1 : parseInt(page);

    try {
      const url = `${logigloUrl}/getAllPostsData?page=${pageNumber}`;
        

      const response = await axios.get(url);

      const limitedPosts = response.data.posts.slice(0, 3);

      setPosts(limitedPosts);
  
    } catch (error) {
      console.error(error);
    }
  };



  useEffect(() => {
    fetchData(1);
  }, []);



  const onClickViewAllBtn = (post) => {
    if (jwtToken) {
      // If jwtToken is present, navigate to createReply page
      history.push(`/forumDashboard?account_id=${account_id}&accountType=${accountType}`);
    } else {
      // If jwtToken is not present, navigate to login page
      history.push(`/forum?account_id=${account_id}&accountType=${accountType}`);
    }
  };


  const onClickPostTitle = (postId) => {
    return () => {
    if (jwtToken) {
      // If jwtToken is present, navigate to createReply page
      history.push(`/forumDashboard/createReply?post_id=${postId}&account_id=${account_id}`);
    } else {
      // If jwtToken is not present, navigate to login page
      history.push("/signin");
    };
  };
  };
  

  // Function to get the first n letters from the text
  const post_text_to_display = 80;
const getFirstNLetters = (text, n) => {
  return text.slice(0, n) + (text.length > n ? "..." : ""); // Add "..." if there are more letters in the text
};




  
  return (
    <>
      <div className="recent-posts-section-outer-con" data-aos="fade-up"
     >
      <div className="recent-posts-section-inner-con">

      <div className="first-carousel-head-con">
                <div className="left-container">
                  <p className="what-wedo-header">Recent Posts</p>
                  
                </div>
                <div className="right-container">
                  {/* Any content you want for the right container */}
                </div>
              </div>


        {/* <h3 className="recent-posts-title">Recent Posts</h3> */}
        <button onClick={onClickViewAllBtn} className="view-all-Btn-styles">View All</button>
      </div>
       <div className="recent-posts-section-con">
      {posts.map((eachPosts) => (
        <div className="post-card-con" key={eachPosts.id}>
        <p onClick={onClickPostTitle(eachPosts.id)} className="post-title-styles">{getFirstNLetters(eachPosts.title, post_text_to_display)}</p>
        <p className="post-description-styles">{getFirstNLetters(eachPosts.text, post_text_to_display)}</p>
        <div className="read-more" onClick={onClickPostTitle(eachPosts.id)}>
                Read More
              </div>
        </div>
        ))}
        </div>

      </div>
    </>
  );
};

export default RecentPostsSection;
