import React, { useEffect, useState, useRef } from "react";
// import emailjs from "@emailjs/browser";
import { Link, Redirect, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { FullColumn } from "../../../components/utility/rowColumn";
import axios from "axios";
import { logigloUrl } from "../../Config/index";
import CustomIcons from "../../../assests/messages";
// import { MdOutlineForum } from "react-icons/md";
import { FaForumbee } from "react-icons/fa";
import useAOS from "../../scrollAnimation";
import Aos from "aos";
import "aos/dist/aos.css";

import "./index.css";
import { duration } from "moment";

const WhyUsSection = () => {
  const [whyUsSection, setwhyUsSection] = useState([]);
  const [usersCount, setUsersCount] = useState();
  useAOS();

  // console.log(usersCount, "opopopo");

  useEffect(() => {
    // Fetch whatWeDoSection data from the backend when the component mounts
    axios
      .get(`${logigloUrl}/whyUsSection`)
      .then((response) => {
        // console.log(response.data, "why_us");
        setwhyUsSection(response.data);
        // console.log(organizedArray);
      })
      .catch((error) => {
        console.error("Error fetching whatWeDoSection data:", error);
      });
  }, []);

  useEffect(() => {
    axios
      .get(`${logigloUrl}/usersCount`)
      .then((response) => {
        const count = response.data.userCount;
        setUsersCount(count);
      })
      .catch((error) => {
        console.error("Error Fetching Users Count:", error);
      });
  }, []);

  return (
    <div data-aos="fade-up" className="why-us-sec-outer-container">
      {whyUsSection.map((eachItem) => (
        <div className="section-combined-con">
          <div
            className="whyUs-section-img-con"
            style={{ backgroundImage: `url(${eachItem.section_img_url})` }}
          ></div>

          <div className="why-us-section-title-con">
            <div className="why-us-section-title-inner-con">
              <p className="why-us-section-title-styles">{eachItem.title}</p>
              <img
                src={eachItem.title_img_url}
                className="whyUs-section-title-img"
                alt="why_us_img"
              />
            </div>
          </div>

          <div className="sub-section-outer-con">
            <div className="both-icons-con">
              <div className="sub-title-1-section-con">
                <img
                  src={eachItem.sub_title_1_icon}
                  className="sub-title-1-icon-styles"
                  alt="title_1_icon_img"
                />
                <p className="sub-title-1">{eachItem.sub_title_1}</p>
              </div>

              <div className="sub-title-1-section-con">
                <img
                  src={eachItem.sub_title_2_icon}
                  className="sub-title-2-icon-styles"
                  alt="title_2_icon_img"
                />
                <p className="sub-title-1">{eachItem.sub_title_2}</p>
              </div>
            </div>

            <img
              src={eachItem.sub_title_img_url}
              className="whyUs-section-sub-title-img"
              alt="sub_title_img"
            />
          </div>

          <div className="user-count-and-products-con">
            <div className="user-count-inner-con">
              <h1>{usersCount} </h1>
              <div className="square-box"></div>
              <p className="users-text"> Users </p>
            </div>

            <div className="products-inner-con">
              <h1>2500</h1>
              <div className="square-box"></div>
              <p className="users-text"> Products </p>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default WhyUsSection;
