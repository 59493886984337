import React, { useEffect, useState, useRef } from "react";
// import emailjs from "@emailjs/browser";
import { Link, Redirect, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { FullColumn } from "../../../components/utility/rowColumn";
import axios from "axios";
import { logigloUrl } from "../../Config/index";
import CustomIcons from "../../../assests/messages";
// import { MdOutlineForum } from "react-icons/md";
import { FaForumbee } from "react-icons/fa";
import useAOS from "../../scrollAnimation";
import Aos from "aos";
import "aos/dist/aos.css";

import "./index.css";
import { duration } from "moment";

const ConnectingGlobeSection = () => {
  const [connectSection, setConnectSection] = useState([]);
  const [connectingSectionContent, setConnectingSectionContent] = useState([]);
  useAOS();

  // console.log(connectSection, "jkjkjkj");
  useEffect(() => {
    // Fetch connectingGlobeSection data from the backend when the component mounts
    axios
      .get(`${logigloUrl}/connectingGlobeSection`)
      .then((response) => {
        // console.log(response.data, "careersSection");
        setConnectSection(response.data.connectingSection);
        setConnectingSectionContent(response.data.connectingSectionContent);
        // console.log(organizedArray);
      })
      .catch((error) => {
        console.error("Error fetching careersSection data:", error);
      });
  }, []);

  return (
    <div data-aos="fade-up" className="connect-globe-sec-outer-container">
      {connectSection.map((eachItem) => (
        <div className="connect-globe-sec-inner-container">
          <h3 className="connect-section-header-styles">{eachItem.header}</h3>
          <div className="connect-section-content-con">
          {connectingSectionContent.map((item) => (
            <div key={item.id} className="connect-section-item">
              <div className="image-container">
                <img
                  src={item.section_img_url}
                  className="each-connect-sec-img"
                  alt=""
                />
                <div className="text-overlay">
                  <p className="each-title-styles">{item.title}</p>
                  <p>{item.description}</p>
                </div>
              </div>
            </div>
          ))}
          </div>
        </div>
      ))}
    </div>
  );
};

export default ConnectingGlobeSection;
